@use '../../assets/styles/colors.scss' as *;
@use '../../assets/styles/fonts.scss' as *;

.ahq-site-logo {
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: $robotoCondensed;
  font-size: 40px;
  font-weight: 500;
  line-height: 55px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: $light-content-1;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 1.5;
  }
}