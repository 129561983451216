@use '../../assets/styles/colors.scss' as *;
@use '../../assets/styles/fonts.scss' as *;

.ahq-cta-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  margin: 0 20px 190px;
  padding: 0;
}