@use '../../assets/styles/colors' as *;
@use '../../assets/styles/fonts' as *;

.ahq-text-block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-bottom: 200px;
  padding: 0 40px;

  &__paragraph{
    display: grid;
    grid-template-columns: minmax(80%, 635px);
    margin: 0;
    font-family: $robotoCondensed;
    font-size: 34px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 1.36px;
    text-align: center;
    color: $light-content-1;
    transition: all .3s ease;

    @media (max-width: 768px) {
      font-size: 4.42vw;
    }
  }
}