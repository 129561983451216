@keyframes curtainHeader {
  0% {
    margin-bottom: 186px;
  }
  100% {
    margin-bottom: 120px;
  }
}

.ahq-home-template__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  padding: 0 160px;
  margin-bottom: 186px;
  background-color: #ffffff;
  transition: all .2s ease;
  animation-name: curtainHeader;
  animation-delay: 1.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  
  @media (max-width:768px) {
    position: absolute;
    top: 20px;
    width: 100%;
    padding: 0 30px;
    margin-bottom: 0;
  }
}