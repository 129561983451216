$helvetica-font-path: '../fonts/helvetica';

@font-face {
  font-family: 'helvetica';
  font-weight: 300;
  font-style: normal;
  src: url('#{$helvetica-font-path}/NeueHelvetica-300.otf');
}

@font-face {
  font-family: 'helvetica';
  font-weight: 300;
  font-style: italic;
  src: url('#{$helvetica-font-path}/NeueHelvetica-300-It.otf');
}

@font-face {
  font-family: 'helvetica';
  font-weight: 400;
  font-style: normal;
  src: url('#{$helvetica-font-path}/NeueHelvetica-400.otf');
}

@font-face {
  font-family: 'helvetica';
  font-weight: 400;
  font-style: italic;
  src: url('#{$helvetica-font-path}/NeueHelvetica-400-It.otf');
}

@font-face {
  font-family: 'helvetica';
  font-weight: 500;
  font-style: normal;
  src: url('#{$helvetica-font-path}/NeueHelvetica-500.otf');
}

@font-face {
  font-family: 'helvetica';
  font-weight: 700;
  font-style: normal;
  src: url('#{$helvetica-font-path}/NeueHelvetica-700.otf');
}
