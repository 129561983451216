@use './colors.scss' as *;

html {
  --light-content-1: $light-content-1;
  --light-content-2: $light-content-2;
}

*{
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #fcfcfc;
    margin: 4px 0;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 20px;
    border: 1px solid #fcfcfc;
  }
}

hr {
  width: 97vw;
  margin: 0 .5vw;
  padding: 0;
  border-top: 1px solid $light-content-1;
}