$roboto-font-path: '../fonts/roboto';

@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: url('#{$roboto-font-path}/roboto-v30-latin-100.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-100.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-100.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-100.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: italic;
  src: url('#{$roboto-font-path}/roboto-v30-latin-100italic.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-100italic.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-100italic.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-100italic.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('#{$roboto-font-path}/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-300.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-300.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-300.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: url('#{$roboto-font-path}/roboto-v30-latin-300italic.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-300italic.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-300italic.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-300italic.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('#{$roboto-font-path}/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-regular.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-regular.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-regular.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('#{$roboto-font-path}/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-italic.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-italic.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-italic.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('#{$roboto-font-path}/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-500.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-500.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-500.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: url('#{$roboto-font-path}/roboto-v30-latin-500italic.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-500italic.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-500italic.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-500italic.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('#{$roboto-font-path}/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: url('#{$roboto-font-path}/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-700.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-700.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-700.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('#{$roboto-font-path}/roboto-v30-latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('#{$roboto-font-path}/roboto-v30-latin-700italic.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-700italic.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-700italic.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-700italic.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url('#{$roboto-font-path}/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-900.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-900.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-900.ttf') format('truetype') /* Safari, Android, iOS */;
}
/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  src: url('#{$roboto-font-path}/roboto-v30-latin-900italic.eot?#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('#{$roboto-font-path}/roboto-v30-latin-900italic.woff') format('woff') /* Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-900italic.woff2') format('woff2') /* Super Modern Browsers */,
    url('#{$roboto-font-path}/roboto-v30-latin-900italic.ttf') format('truetype') /* Safari, Android, iOS */;
}
