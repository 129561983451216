@use '../../assets/styles/colors' as *;
@use '../../assets/styles/fonts' as *;

.ahq-pass-link{
  display: contents;

  &__input{
    padding: 8px;
    border: none;
    border-bottom: 1px solid $light-content-2;
    font-size: 16px;
    letter-spacing: .5px;
    margin-bottom: 4px;
    
    &:focus {
      outline: 0;
      border: none;
      border-bottom: 1px solid $light-content-1;
    }
    
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &__error {
    visibility: hidden;
    font-family: $robotoCondensed;
    font-size: 14px;
    letter-spacing: 1.28px;
    color: darkred;

    &--show {
      visibility: visible;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border: 2px solid $light-content-2;
    font-family: $robotoCondensed;
    font-size: 18px;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    background-color: #fff;
    cursor: pointer;
    border-radius: 100px;

    &:hover {
      .ahq-pass-link__button-arrow {
        margin-right: 0;
        padding-left: 12px;
      }
    }

    &-arrow {
      margin-right: 12px;
      font-size: 24px;
      line-height: 0;
      transition: all .3s ease;
    }
  }
}