@use '../../assets/styles/fonts.scss' as *;
@use '../../assets/styles/colors.scss' as *;

// $slotMachineBanner: 233px;
$slotMachineBanner-height: 152px;

@keyframes v-spin {
  0%   {top: 0px;}
  // 30%  {top: -$slotMachineBanner-height;}
  // 50%  {top: -$slotMachineBanner-height;}
  // 80%  {top: -$slotMachineBanner-height * 2;}
  // 100%  {top: -$slotMachineBanner-height * 2;}
  30%  {top: -100%;}
  50%  {top: -100%;}
  80%  {top: -200%;}
  100%  {top: -200%;}
}

.ahq-slot-machine-banner {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: $slotMachineBanner-height;
  margin: 100px 0 70px;
  font-size: 10vw;
  letter-spacing: 13.6px;
  text-transform: uppercase;
  color: $light-content-1;

  &__fixed {
    font-family: $robotoCondensed;
    position: relative;
    overflow: hidden;
  }

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    font-family: $robotoCondensed;
    text-align: right;
    animation: v-spin 6s infinite;
    background: #ffffff;
  }

  @media (max-width: 768px) {
    padding: 60px 20px 0;
    letter-spacing: 8px;
  }
}