@keyframes curtain {
  0% {
    min-height: 100vh;
  }
  100% {
    min-height: 80vh;
  }
}

.ahq-home-template {
  display: flex;
  flex-direction: column;

  &__introduction {
    padding-top: 84px;
    // margin-bottom: 120px;
    min-height: 100vh;
    // // transition: all 2s ease;
    // // min-height: 80vh;
    animation-name: curtain;
    animation-delay: 1.2s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  &__body {
    margin-bottom: 190px;
  }

  &__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
  }

  @media (max-width: 768px) {
    &__introduction {
      display: flex;
      flex-direction: column;
      padding-top: 0;

      .ahq-hero-list-wrapper {
        flex-grow: 1;
      }
    }

    &__body {
      margin-bottom: 80px;
    }

    &__footer {
      margin-bottom: 70px;
    }
  }
}