@use '../../assets/styles/colors' as *;
@use '../../assets/styles/fonts' as *;

.ahq-modal {
  z-index: 99; 
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  
  &__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $light-content-1;
    opacity: 0;
    transition: all .2s ease;
  }
  
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    padding: 20px;
    border: none;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    background: transparent;
    cursor: pointer;
  }


  &--visible &__overlay {
    opacity: .8;
  }

  &__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    max-width: 80%;
    padding: 48px;
    background-color: #ffffff;
    z-index: 2
  }
}