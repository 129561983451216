
@use '../../assets/styles/fonts.scss' as *;
@use '../../assets/styles/colors.scss' as *;

.ahq-links-list {
  display: flex;
  align-items: center;
  gap: 36px;
  margin: 0;
  padding:0;
  
  &__item {
    display: block;
    font-family: $robotoCondensed;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    text-decoration: none;
    color: $light-content-2;
    transition: all .6s ease;
    cursor: pointer;

    &:hover{
      color: $light-content-1;
    }
  }

  @media (max-width: 768px) {
    gap: 24px;

    &--mob-column{
      flex-direction: column;
    }
  }
}
