$robotoCondensed-font-path: '../fonts/roboto-condensed';

/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 300;
  font-style: normal;
  src: url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300.eot?#iefix') format('embedded-opentype')
      /* IE6-IE8 */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300.woff2') format('woff2')
      /* Super Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300.woff') format('woff') /* Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300.ttf') format('truetype')
      /* Safari, Android, iOS */;
}
/* roboto-condensed-300italic - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 300;
  font-style: italic;
  src: url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300italic.eot?#iefix') format('embedded-opentype')
      /* IE6-IE8 */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300italic.woff') format('woff') /* Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300italic.woff2') format('woff2')
      /* Super Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-300italic.ttf') /* Safari, Android, iOS */
      format('truetype');
}
/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 400;
  font-style: normal;
  src: url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype')
      /* IE6-IE8 */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-regular.woff') format('woff') /* Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-regular.woff2') format('woff2')
      /* Super Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-regular.ttf') format('truetype')
      /* Safari, Android, iOS */;
}
/* roboto-condensed-italic - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 400;
  font-style: italic;
  src: url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-italic.eot?#iefix') format('embedded-opentype')
      /* IE6-IE8 */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-italic.woff') format('woff') /* Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-italic.woff2') format('woff2')
      /* Super Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-italic.ttf') format('truetype')
      /* Safari, Android, iOS */;
}
/* roboto-condensed-medium - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 500;
  font-style: normal;
  src: url('#{$robotoCondensed-font-path}/RobotoCondensed-Medium.ttf') format('truetype') /* Modern Browsers */;
}
/* roboto-condensed-medium-italic - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 500;
  font-style: italic;
  src: url('#{$robotoCondensed-font-path}/RobotoCondensed-MediumItalic.ttf') format('truetype')
    /* Safari, Android, iOS */;
}
/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 700;
  font-style: normal;
  src: url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype')
      /* IE6-IE8 */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700.woff') format('woff') /* Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700.woff2') format('woff2')
      /* Super Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700.ttf') format('truetype')
      /* Safari, Android, iOS */;
}
/* roboto-condensed-700italic - latin */
@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 700;
  font-style: italic;
  src: url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700italic.eot?#iefix') format('embedded-opentype')
      /* IE6-IE8 */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700italic.woff') format('woff') /* Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700italic.woff2') format('woff2')
      /* Super Modern Browsers */,
    url('#{$robotoCondensed-font-path}/roboto-condensed-v25-latin-700italic.ttf') format('truetype')
      /* Safari, Android, iOS */;
}
