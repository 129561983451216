@use '../../assets/styles/fonts.scss' as *;
@use '../../assets/styles/colors.scss' as *;

.ahq-hero-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__item{
    display: block;
    padding: 0;
    margin: 0;
    font-family: $robotoCondensed;
    font-size: 64px;
    font-weight: 500;
    line-height: 1.125;
    letter-spacing: 2.56px;
    text-transform: uppercase;
    color: $light-content-1;
    text-align: center;
    transition: all .3s ease;

    @media (max-width: 768px) {
      margin-bottom: 12px;
      font-size: 8.33vw;
    }
  }

  &--light {
    .ahq-hero-list__item {
      font-size: 50px;
      font-weight: 300;
      line-height: 1.44;
      letter-spacing: 2px;
      transition: all .3s ease;

      @media (max-width: 768px) {
        font-size: 6.51vw;
      }
    }
  }
}