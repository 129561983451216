@use '../../assets/styles/colors' as *;
@use '../../assets/styles/fonts' as *;

$cta-maxWidth: 810px;

.ahq-cta {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 810px;
  max-width: 96vw;
  aspect-ratio: 16 / 9;
  cursor: pointer;

  &--framed {
    width: 100%;
    max-width: 1050px;
    padding: 100px 120px;
    border: 1px solid $light-content-1;
  }
  
  &__image{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-blend-mode: saturation;
    transition: all .3s ease;
  }

  &__title{
    position: absolute;
    // max-width: $cta-maxWidth;
    max-width: 90%;
    margin: 0;
    padding: 0;
    font-family: $robotoCondensed;
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    color: $light-content-1;
    text-align: center;

    @media (max-width: 768px) {
      transition: all .3s ease;
      font-size: 10.41vw;
    }
  }

  &:hover {
    .ahq-cta{
      &__image {
        filter: blur(6px);
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px 12px;
  }
}